export const appConfigs = {
    app_name: "Link Energy",
    org_name: process.env.NODE_ENV !== "production" ? "Link Energy (Dev)" : "Link Energy",
    org_url: "https://congo-power.africa",
    api_url: process.env.NODE_ENV !== "production" ? "http://localhost:8866/api/" : (window.location.host === "congo-power.sandbox.co.ke" ? "https://congo-power.sandbox.co.ke:8167/api/" : "https://congo-power.africa:8167/api/"),
    portal_api_url: "https://congo-power.sandbox.co.ke:8167/api/",
    
    auth_key: process.env.NODE_ENV !== "production" ? "XNAgZSnoEsf55nlbmDIJ" : "rK32640byHUTixKE7ge1",
    uploads_url: process.env.NODE_ENV !== "production" ? "http://localhost:8866/u0veDcqIsErQVKXuXeM3/" : (window.location.host === "congo-power.sandbox.co.ke" ? "https://congo-power.sandbox.co.ke:8167/u0veDcqIsErQVKXuXeM3/" : "https://congo-power.africa:8167/u0veDcqIsErQVKXuXeM3/"),
    recaptcha_site_key: "6LfdYeQZAAAAAPijoDtW-p4RLdUZBnc0brmarW5M",
};