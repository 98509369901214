import React, { useEffect } from "react";//
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import * as actions from "../modules/Settings/_redux//orders/ordersActions";
import * as truckActions from "../modules/Settings/_redux/trucks/trucksActions";
import { Alert } from "react-bootstrap";
import {TilesWidget11 } from "../../_power_uber/_partials/widgets";//,CustomBarChart
import { Maps } from "../widgets/Maps";

export function DashboardPage() {
    

    const { user_id,first_name,totalCapacity, totalSoldLitres, totalCost, totalOrders, totalLitresAvailable } = useSelector(
        (state) => ({
            user_id: state.auth.user.id || "",
            first_name: state.auth.user.first_name || "",

            totalCapacity: state.orders.totalCapacity || 0,
            totalSoldLitres: state.orders.totalSoldLitres || 0,
            totalCost: state.orders.totalCost || 0,
            totalOrders: state.orders.totalOrders || 0,
            totalLitresAvailable: state.orders.totalLitresAvailable || 0,

        }),
        shallowEqual
    );

    const dispatch = useDispatch();
    useEffect(() => {
        

        dispatch(actions.getTotalCapacity({}));
        dispatch(actions.getTotalSoldLitres({}));
        dispatch(actions.getTotalCost({}));
        dispatch(actions.getTotalOrders({}));
        dispatch(actions.getTotalLitresAvailable({}));


        dispatch(truckActions.fetchTrucks());

        // eslint-disable-next-line 
    }, [dispatch, user_id]);

    //function to display a HTML grid of 3 by two

    const grid = (widget1, widget2, widget3, widget4, widget5) => {
        return (
            <div className="row">
                <div className="col-xl-4">
                    {widget1}
                </div>
                <div className="col-xl-4">
                    {widget2}
                </div>
                <div className="col-xl-4">
                    {widget3}
                </div>
                <div className="col-xl-6">
                    {widget4}
                </div>
                <div className="col-xl-6">
                    {widget5}
                </div>
            </div>
        )
    }

    return (<>
        <div className="row">
            <div className="col-xl-12">
                <div className="card card-custom gutter-b card-stretch">
                    <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                            <h2>Link Energy - <em>Fuel Distributors</em></h2>
                            <Alert variant="primary"> Welcome {first_name}!</Alert>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="col-xl-12 mt-5">
                {grid(
                    <TilesWidget11 className="card-stretch gutter-b" baseColor="primary" value={totalCapacity} label="Total Capacity" />,
                    <TilesWidget11 className="card-stretch gutter-b" baseColor="info" value={totalSoldLitres.toFixed(2)} label="Total Litres Sold" />,
                    <TilesWidget11 className="card-stretch gutter-b" baseColor="success" value={totalCost.toFixed(2)} label="Total Payments (USD)" />,
                    <TilesWidget11 className="card-stretch gutter-b" baseColor="info" value={totalOrders} label="Total Orders Made" />,
                    <TilesWidget11 className="card-stretch gutter-b" baseColor="primary" value={totalLitresAvailable.toFixed(2)} label="Total Unsold Fuel (ltrs)" />
                )}
            </div>
            <div className="col-xl-12 mb-5">
                <Maps />
            </div>
            
            
        </div>
    </>);
}
